
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['selectPayment'],
  name: 'TelegramPaymentDetail',
  props: {
    detail: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    currentClass() {
      return this.selected ? `selected ${this.detail._id}` : '';
    }
  }
})
