
import { Channel } from "@/interfaces/channel/channel.dto";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";
import { defineComponent, PropType } from "vue-demi";

export default defineComponent({
  name: 'RequestQuestion',
  props: {
    question: {
      type: Object as PropType<Channel.Dto['request']['questions'][number]>,
      required: true
    },
    question_subscriber: {
      type: Object as PropType<Subscriber.Dto['questions'][number]>,
      required: true
    }
  },
})
