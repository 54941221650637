import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f02d012"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "common--payment flex-column"
}
const _hoisted_2 = { class: "fz16 fw500" }
const _hoisted_3 = { class: "container flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_type = _resolveComponent("type")!

  return (!_ctx.isOneDetailByType)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.currentTitle), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTypes, (typee) => {
            return (_openBlock(), _createBlock(_component_type, {
              key: typee.id,
              type: typee,
              selected: typee.id === _ctx.selected,
              details: _ctx.details,
              payment: _ctx.payment,
              isOne: _ctx.activeTypes.length === 1,
              onSelectType: ($event: any) => (_ctx.selectType(typee.id)),
              onSelectPayment: _cache[0] || (_cache[0] = (e) => _ctx.$emit('selectPayment', e))
            }, null, 8, ["type", "selected", "details", "payment", "isOne", "onSelectType"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}