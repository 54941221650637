
import { defineComponent } from "vue-demi";
import TelegramPayment from "@/components/telegram/payment/index.vue";

export default defineComponent({
  name: 'TelegramPaymentView',
  components: {
    TelegramPayment,
  },
});
