
import { defineComponent } from "vue-demi";
import { useAPI } from "@/use";

export default defineComponent({
  name: "PaymentYoomoney",
  data() {
    return {
      pending: false,
    }
  },
  props: {
    token: {
      type: String,
      required: true
    },
    tariff: {
      type: String,
      required: true
    },
    promocode: {
      type: String,
    },
    price: {
      type: Number,
      required: true
    },
  },
  methods: {
    async submitPayment() {
      this.pending = true;
      const data = await this.initialYoomoneyPayment();
      
      const form = this.jq("<form/>", {
        action: "https://yoomoney.ru/quickpay/confirm.xml",
        method: "post",
        style: { display: "none" },
      });
      for (const key in data)
        form.append(this.jq("<input/>", { type: "hidden", name: key, value: data[key] }));

      this.jq("body").append(form);
      form.submit();
    },
    async initialYoomoneyPayment() {
      const result = await useAPI().payment.initialPaymentMethod('yoomoney', this.token, this.tariff, this.promocode);
      return result.data.data;
    }
  },
});
