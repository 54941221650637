import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6052bfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "type--icon" }
const _hoisted_2 = { class: "type--name fz18 fw500" }
const _hoisted_3 = {
  key: 0,
  class: "type--details grid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail = _resolveComponent("detail")!

  return (_openBlock(), _createElementBlock("div", {
    class: "type common flex-column",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectType')))
  }, [
    (!_ctx.isOne)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["type--container flex items-center", { selected: _ctx.selected }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type.icon)))
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.type.name), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.selected && _ctx.currentDetails.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentDetails, (detail) => {
                return (_openBlock(), _createBlock(_component_detail, {
                  key: detail._id,
                  detail: detail,
                  selected: _ctx.payment === detail._id,
                  onSelectPayment: ($event: any) => (_ctx.$emit('selectPayment', detail._id))
                }, null, 8, ["detail", "selected", "onSelectPayment"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}