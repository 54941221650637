
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";
import { mask } from "vue-the-mask";

export default defineComponent({
  emits: ["selectPayment", "selectPromocode"],
  name: "PaymentPromocode",
  props: {
    tariff: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      promocode: "",
      promocodeMask: "XXXXXXXX",
      disabledPromocode: false,
      pending: false,
    };
  },
  mounted() {
    const input = this.$refs.promocode as HTMLInputElement;
    this.emitter.on("update-promocode", () => {
      this.promocode = "";
      this.disabledPromocode = false;
    });
    input.addEventListener("paste", (e: ClipboardEvent) => {
      this.promocode = e.clipboardData?.getData("text") || "";
      this.promocodeCheck();
    });
  },
  methods: {
    async promocodeCheck() {
      if (this.pending) return;
      if (this.promocode.length !== 8 ) return this.notification("Промокод должен содержать 8 символов!", "static");

      this.pending = true;
      const result = useAPI().promocode.checkMethod(
        this.$route.query.token as string,
        this.tariff,
        this.promocode
      );
      result
        .then((res) => {
          this.disabledPromocode = true;
          this.pending = false;
          this.notification("Промокод применен!", "error");
          this.$emit("selectPromocode", res.data.data);
        })
        .catch((err) => {
          this.promocode = "";
          this.notification(
            err.response.data?.message || err.response.data,
            "error"
          );
          this.pending = false;
        });
    },
    async submitPayment() {
      const result = useAPI().subscriber.paymentMethod(
        this.$route.query.token as string,
        this.tariff,
        this.promocode
      );
      result
        .then((res) => this.$router.push(`/payment/success`))
        .catch((err) => this.notification(err.response.data.message, "error"));
    },
    notification(message: string, type: string) {
      this.$store.commit("createGeneralNotification", { message, type });
    },
  },
  directives: {
    mask,
  },
});
