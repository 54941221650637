
import { defineComponent } from "vue-demi";
import { useAPI } from "@/use";

export default defineComponent({
  name: "PaymentTinkoff",
  data() {
    return {
      pending: false,
    }
  },
  props: {
    token: {
      type: String,
      required: true
    },
    tariff: {
      type: String,
      required: true
    },
    promocode: {
      type: String,
    },
    price: {
      type: Number,
      required: true
    },
    auto: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    async submitPayment() {
      this.pending = true;
      const result = await useAPI().payment.initialPaymentMethod('tinkoff', this.token, this.tariff, this.promocode, this.auto);
      location.replace(result.data.data.url);
    },
  },
});
