import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58acc647"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "common flex-column"
}
const _hoisted_2 = { class: "common--channel flex items-center" }
const _hoisted_3 = { class: "image" }
const _hoisted_4 = {
  key: 0,
  alt: ""
}
const _hoisted_5 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_6 = { class: "name fz18 fw500" }
const _hoisted_7 = {
  key: 2,
  class: "links center flex-column"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  class: "common"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_channel_icon = _resolveComponent("channel-icon")!
  const _component_request = _resolveComponent("request")!
  const _component_tariff_list = _resolveComponent("tariff-list")!
  const _component_detail_list = _resolveComponent("detail-list")!
  const _component_common_promocode = _resolveComponent("common-promocode")!
  const _component_payment_methods = _resolveComponent("payment-methods")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.channel && _ctx.subscriber && !_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.channel.image)
              ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_4, null, 512)), [
                  [_directive_lazy, _ctx.channel.image.src]
                ])
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_channel_icon, { color: "#6B6C6D" })
                ]))
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.channel.name), 1)
        ]),
        (!_ctx.requestInfo)
          ? (_openBlock(), _createBlock(_component_request, {
              key: 0,
              subscriber: _ctx.subscriber,
              request: _ctx.channel.request,
              requestInfo: _ctx.requestInfo,
              onChangeRequestInfo: _ctx.changeRequestInfo
            }, null, 8, ["subscriber", "request", "requestInfo", "onChangeRequestInfo"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, { name: "icon" }, {
          default: _withCtx(() => [
            (_ctx.requestInfo)
              ? (_openBlock(), _createBlock(_component_tariff_list, {
                  key: 0,
                  tariffs: _ctx.channel.tariffs,
                  tariff_id: _ctx.tariff?._id,
                  onSelectTariff: _ctx.selectTariff
                }, null, 8, ["tariffs", "tariff_id", "onSelectTariff"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "icon" }, {
          default: _withCtx(() => [
            (_ctx.tariff)
              ? (_openBlock(), _createBlock(_component_detail_list, {
                  key: 0,
                  details: _ctx.selectedDetails,
                  global: _ctx.global,
                  payment: _ctx.selectedPayment,
                  onSelectPayment: _ctx.selectPayment,
                  onClearPayment: _ctx.clearPayment
                }, null, 8, ["details", "global", "payment", "onSelectPayment", "onClearPayment"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "icon" }, {
          default: _withCtx(() => [
            (_ctx.selectedPayment)
              ? (_openBlock(), _createBlock(_component_common_promocode, {
                  key: 0,
                  tariff: _ctx.tariff?._id,
                  onSelectPromocode: _ctx.selectPromocode
                }, null, 8, ["tariff", "onSelectPromocode"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.selectedPayment)
          ? (_openBlock(), _createBlock(_component_payment_methods, {
              key: 1,
              payment: _ctx.selectedPayment,
              tariff: _ctx.tariff,
              promocode: _ctx.promocode,
              auto: _ctx.subscriber.auto_payment
            }, null, 8, ["payment", "tariff", "promocode", "auto"]))
          : _createCommentVNode("", true),
        (_ctx.isChannelLinks)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.channel?.telegram.policy)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.channel?.telegram.policy
                  }, "Политика конфидециальности", 8, _hoisted_8))
                : _createCommentVNode("", true),
              (_ctx.channel?.telegram.offer)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: _ctx.channel?.telegram.offer
                  }, "Публичная офферта", 8, _hoisted_9))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(_ctx.error), 1)
        ]))
      : _createCommentVNode("", true)
}