export const masks = {
  RUS: { mask: '(###)###-##-##', code: '+7', name: 'Россия' },
  USA: { mask: '(###)###-####', code: '+1', name: 'США' },
  CAN: { mask: '(###)###-####', code: '+1', name: 'Канада' },
  UKR: { mask: '(##)###-##-##', code: '+380', name: 'Украина' },
  BLR: { mask: '(##)###-##-##', code: '+375', name: 'Белорусь' },
  KAZ: { mask: '(###)###-##-##', code: '+7', name: 'Казахстан' },
  UZB: { mask: '(##)###-####', code: '+998', name: 'Узбекистан' },
  TUR: { mask: '(###)###-#####', code: '+90', name: 'Турция' },
  THA: { mask: '(##)###-####', code: '+66', name: 'Тайланд' },
  TJK: { mask: '(##)###-####', code: '+992', name: 'Таджикистан' },
  SVK: { mask: '(###) ###-###', code: '+421', name: 'Словакия' },
  SVN: { mask: '(##)###-###', code: '+386', name: 'Словения' },
  SGP: { mask: '(####)####', code: '+65', name: 'Сингапур' },
  SAU: { mask: '(#)####-####', code: '+966', name: 'Саудовская Аравия' },
  ROU: { mask: '(##)###-####', code: '+40', name: 'Румыния' },
  KOR: { mask: '(##)####-####', code: '+82', name: 'Южная Корея' },
  POL: { mask: '(###) ###-###', code: '+48', name: 'Польша' },
  ARE: { mask: '(##)###-####', code: '+971', name: 'ОАЭ' },
  NOR: { mask: '(###) ##-###', code: '+47', name: 'Норвегия' },
  NLD: { mask: '(##)###-####', code: '+31', name: 'Нидерланды' },
  MNG: { mask: '(##)##-####', code: '+976', name: 'Монголия' },
  LTU: { mask: '(###) ##-###', code: '+370', name: 'Литва' },
  LVA: { mask: '(##)###-###', code: '+371', name: 'Латвия' },
  CHN: { mask: '(###) ####-####', code: '+86', name: 'Китай' },
  KGZ: { mask: '(###) ###-###', code: '+996', name: 'Киргизия' },
  ESP: { mask: '(###) ###-###', code: '+34', name: 'Испания' },
  ITA: { mask: '(###) ####-###', code: '+39', name: 'Италия' },
  IDN: { mask: '(###) ###-##-###', code: '+62', name: 'Индонезия' },
  IND: { mask: '(####) ###-###', code: '+91', name: 'Индия' },
  ISR: { mask: '(######)####', code: '+972', name: 'Израиль' },
  EGY: { mask: '(###) ###-####', code: '+20', name: 'Египет' },
  DNK: { mask: '(##)##-##-##', code: '+45', name: 'Дания' },
  GEO: { mask: '(###) ###-###', code: '+995', name: 'Грузия' },
  GRC: { mask: '(###) ###-####', code: '+30', name: 'Греция' },
  DEU: { mask: '(###) ###-#####', code: '+49', name: 'Германия' },
  VNM: { mask: '(##)####-###', code: '+84', name: 'Вьетнам' },
  GBR: { mask: '(##)####-#####', code: '+44', name: 'Великобритания' },
  BRA: { mask: '(##) #####-####', code: '+55', name: 'Бразилия' },
  BGR: { mask: '(###) ###-###', code: '+359', name: 'Болгария' },
  BEL: { mask: '(###) ###-###', code: '+32', name: 'Бельгия' },
  ARM: { mask: '(##)###-###', code: '+374', name: 'Армения' },
  AZE: { mask: '(##)###-##-##', code: '+994', name: 'Азербайджан' },
  AUS: { mask: '(##)####-####', code: '+61', name: 'Австралия' },
  ZAF: { mask: '(##)###-####', code: '+27', name: 'ЮАР' },
  EST: { mask: '(####)####', code: '+372', name: 'Эстония' },
  SWE: { mask: '(##)###-####', code: '+46', name: 'Швеция' },
  CHE: { mask: '(##)###-####', code: '+41', name: 'Швейцария' },
  CZE: { mask: '(###) ###-###', code: '+420', name: 'Чехия' },
  MNE: { mask: '(##)###-###', code: '+382', name: 'Черногория' },
  HRV: { mask: '(##)###-####', code: '+385', name: 'Хорватия' },
  FRA: { mask: '(###) ###-####', code: '+33', name: 'Франция' },
  FIN: { mask: '(###)#######', code: '+358', name: 'Финляндия' },
  PHL: { mask: '(###) ###-####', code: '+63', name: 'Филиппины' },
  TKM: { mask: '(#)###-####', code: '+993', name: 'Туркменистан' },
  PRT: { mask: '(##)###-####', code: '+351', name: 'Португалия' },
  NZL: { mask: '(###) ###-###', code: '+64', name: 'Новая Зеландия' },
  JPN: { mask: '(##)####-####', code: '+81', name: 'Япония' },
}
