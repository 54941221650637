
import { defineComponent, markRaw } from "vue-demi";
import { Channel } from "@/interfaces/channel/channel.dto";
import { useAPI } from "@/use";
import { BotpayIcon, ChannelIcon, RussianIcon, WorldIcon } from '@/plugins/icons';
import { ProdamusIcon, RobokassaIcon, TinkoffIcon, YoomoneyIcon } from "@/plugins/icons";
import { dynamicsObject } from "@/interfaces";

import TariffList from './tariff/List.vue';
import DetailList from './detail/List.vue';
import CommonPromocode from './promocode/index.vue';
import Request from './request/index.vue';
import PaymentMethods from './methods/index.vue';
import { Tariff } from "@/interfaces/tariff/tariff.dto";
import { Promocode } from "@/interfaces/promocode/promocode.dto";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";
import { ACTIVE_TYPES } from "@/utils/enums";

export default defineComponent({
  name: 'TelegramPaymentMain',
  props: {
    channel_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      global: [
        { id: "russian", name: "Внутри РФ", icon: markRaw(RussianIcon), list: ['yoomoney', 'tinkoff', 'botpay_russian'] },
        { id: "world", name: "Остальной мир", icon: markRaw(WorldIcon), list: ['botpay_world'] },
      ],
      details: [
        { selected: false, _id: "botpay_russian", name: "Botpay", icon: markRaw(BotpayIcon) },
        { selected: false, _id: "botpay_world", name: "Botpay", icon: markRaw(BotpayIcon) },
        { selected: false, _id: "yoomoney", name: "Yoomoney", icon: markRaw(YoomoneyIcon) },
        { selected: false, _id: "tinkoff", name: "Tinkoff", icon: markRaw(TinkoffIcon) },
        // { selected: false, _id: "robokassa", name: "Robokassa", icon: markRaw(RobokassaIcon) },
        // { selected: false, _id: "prodamus", name: "Prodamus", icon: markRaw(ProdamusIcon) },
      ],
      error: '',
      tariff: null as Tariff.Dto | null,
      selectedPayment: null as string | null,
      request: null as dynamicsObject | null,
      requestInfo: true,

      channel: null as Channel.Dto | null,
      promocode: null as Promocode.Dto | null,
      subscriber: null as Subscriber.Dto | null,
    }
  },
  created() {
    this.getPaymentDetailsMethod();
  },
  mounted() {
    if (!this.$route.query.token) this.error = 'Ошибка данных!';
    this.telegramScript();
  },
  computed: {
    isRequestChannel() {
      return ((this.channel?.request?.phone && !this.subscriber?.phone) || 
              (this.channel?.request?.email && !this.subscriber?.email) ||
              (this.subscriber?.questions?.find(q => !q.answer)));
    },
    selectedDetails() {
      return this.details.filter(d => d.selected);
    },
    isChannelLinks() {
      return this.channel?.telegram.policy || this.channel?.telegram.offer;
    }
  },
  methods: {
    telegramSettings() {
      if (!(window as dynamicsObject).Telegram.WebApp.isExpanded)
        (window as dynamicsObject).Telegram.WebApp.expand();
    },
    telegramScript() {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-web-app.js";
      document.body.appendChild(script);
      setTimeout(() => {
        this.telegramSettings();
      }, 100);
    },
    async getPaymentDetailsMethod() {
      const result = useAPI().payment.getDetailsMethod(this.$route.query.token as string);
      result
        .then(({ data }) => {
          this.channel = data.data.channel;
          this.subscriber = data.data.subscriber;

          if (!this.channel || !this.subscriber) return this.error = 'Ошибка данных!';
          if (this.subscriber.status === ACTIVE_TYPES.BANNED) return this.error = 'Ты был заблокирован администратором сообщества!';

          for (const detail of this.details)
            detail.selected = data.data.channel.payment[detail._id];

          // TODO: Если отвечал уже на какой-то вопрос - сразу подтягивать его
          if (this.isRequestChannel)
            this.requestInfo = false;
        })
        .catch(err => {
          this.$router.push('/payment/error');
        })
    },
    handlerSubscriberAuto(auto: boolean) {
      if (!this.subscriber) return;
      this.subscriber.auto_payment = auto;
    },
    selectPromocode(promocode: Promocode.Dto) {
      this.promocode = promocode;
    },
    changeRequestInfo(status: boolean) {
      this.requestInfo = status;
    },
    selectTariff(tariff: Tariff.Dto) {
      if (this.tariff && this.tariff._id !== tariff._id) {
        this.emitter.emit('update-promocode');
        this.promocode = null;
      }
      this.tariff = tariff;
      this.scroll();
      
      if (!this.subscriber) return;
      this.subscriber.auto_payment = false;
    },
    selectPayment(detail: string) {
      this.selectedPayment = detail;
      this.scroll();
    },
    clearPayment(id: string) {
      this.selectedPayment = null;
      const type = this.global.find(type => type.id === id);
      if (!type) return;

      const details = this.selectedDetails.filter(detail => type.list.includes(detail._id));
      if (details.length === 1) this.selectedPayment = details[0]._id;
    },
    scroll() {
      setTimeout(() => {
        this.jq('.telegram').animate({ scrollTop: 906 });
      }, 10);
    },
    notification(message: string, type: string) {
      this.$store.commit('createGeneralNotification', { message, type });
    }
  },
  components: {
    ChannelIcon,
    TariffList,
    DetailList,
    CommonPromocode,
    Request,
    PaymentMethods
  },
})
