
import { defineComponent, PropType } from "vue-demi";
import { Tariff } from "@/interfaces/tariff/tariff.dto";
import CommonTariff from './index.vue';

export default defineComponent({
  emits: ['selectTariff'],
  name: "TariffList",
  props: {
    tariffs: {
      type: Array as PropType<Array<Tariff.Dto>>,
      required: true,
    },
    tariff_id: {
      type: String,
    }
  },
  components: {
    CommonTariff
  }
});
