
import { defineComponent } from "vue-demi";
import { useAPI } from "@/use";

export default defineComponent({
  name: "PaymentBotpay",
  data() {
    return {
      pending: false,
    }
  },
  props: {
    token: {
      type: String,
      required: true
    },
    tariff: {
      type: String,
      required: true
    },
    promocode: {
      type: String,
    },
    price: {
      type: Number,
      required: true
    }
  },
  methods: {
    async submitPayment() {
      this.pending = true;
      const result = await useAPI().payment.initialPaymentMethod('botpay_russian', this.token, this.tariff, this.promocode);
      location.replace(result.data.data.url);
    },
  },
});
