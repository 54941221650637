import { Channel } from "@/interfaces/channel/channel.dto";
import { Tariff } from "@/interfaces/tariff/tariff.dto";
import { isEmptyUserPayment } from "@/utils/user";
import store from "@/store";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";

const errors = [
  { id: 'deleted', error_value: true, message: 'Бот был удалён из этого сообщества!', button: 'Настроить' },
  { id: 'can_invite_users', error_value: false, message: 'Бот не имеет прав добавлять/удалять подписчиков!', button: 'Обновить' },
  { id: 'link', error_value: null, message: 'Ссылка для подписчиков не была сгенерирована!', button: 'Сгенерировать' },
] as Array<{ id: 'deleted' | 'link' | 'can_invite_users', error_value: any, message: string, button: string }>

export const isChannelErrors = (channel: Channel.Dto) => {
  const user = store.getters.user;
  const payment = !!Object.values(channel.payment).find(p => !!p);
  
  return channel.telegram.deleted || 
         !channel.telegram.can_invite_users ||
         !channel.telegram.can_post_messages ||
         !channel.telegram.link ||
         !channel.tariffs.length ||
         !channel.active || 
         !payment ||
         +user?.balance === 0 ||
         isEmptyUserPayment(user?.payment)
}

export const currentChannelError = (channel: Channel.Dto) => {
  const user = store.getters.user;
  const payment = !!Object.values(channel.payment).find(p => !!p);

  const error = errors.filter(e => channel.telegram[e.id] === e.error_value)[0];
  if (error) return error;
  if (+user?.balance === 0) return { id: 'replenishment', message: 'Необходимо пополнить баланс для продолжения работы!', button: 'Пополнить' }
  if (isEmptyUserPayment(user?.payment)) return { id: 'payment_detail', message: 'Необходимо настроить платежные данные!', button: 'Настроить' }
  if (!channel.active) return { id: 'active', message: 'Необходимо завершить настройку сообщества!', button: 'Настроить' }
  if (!channel.tariffs.length) return { id: 'tariff', message: 'Чтобы сообщество работало, необходимо добавить тариф!', button: 'Добавить' }
  if (!payment) return { id: 'payment_system', message: 'Необходимо выбрать платежную систему!', button: 'Выбрать' }
}

export const CHANNEL_COMMON_INPUTS = (channel: Channel.Dto) => ({
  inputs: [
    { required: false, show: true, id: 'telegram__shortname', placeholder: 'Укажи уникальный идентификатор сообщества: Example', type: 'text', name: 'Тело ссылки на сообщество (идентификатор после ?start=) P.S. основная ссылка при этом не удаляется', grid: '1 / 9', disabled: false },
    { required: false, show: true, id: 'name', placeholder: 'Введи название сообщества', type: 'text', name: 'Название сообщества (+ меняется в телеграме)', grid: '1 / 5', disabled: false },
    { required: false, show: true, id: 'telegram__support', placeholder: 'Введи логин или ссылку', type: 'text', name: 'Логин TG-поддержки или ссылка на сторонний сервис', grid: '5 / 9', disabled: false },
    { required: false, show: true, id: 'telegram__policy', placeholder: 'Вставьте ссылку - https://example.com', type: 'text', name: 'URL - Политики конфидециальности', grid: '1 / 5', disabled: false },
    { required: false, show: true, id: 'telegram__offer', placeholder: 'Вставьте ссылку - https://example.com', type: 'text', name: 'URL - Публичной оферты', grid: '5 / 9', disabled: false },
    { required: false, show: true, id: 'telegram__description', placeholder: 'Введи описание вашего сообщества', type: 'textarea', name: 'Описание сообщества - описание не изменится тут, если вы его поменяете в телеграме', grid: '1 / 9', disabled: false },
  ],
  data: {
    name: channel.name,
    telegram__support: channel?.telegram?.support,
    telegram__policy: channel?.telegram?.policy,
    telegram__offer: channel?.telegram?.offer,
    telegram__shortname: channel?.telegram?.shortname,
    telegram__description: channel?.telegram?.description,
  },
});

export const CHANNEL_GREETINGS_INPUTS = (channel: Channel.Dto) => ({
  inputs: [
    { required: false, show: false, id: 'greetings__image', placeholder: '', type: 'image', name: 'Изображение', grid: '1 / 5', disabled: false },
    { required: false, show: true, id: 'greetings__text', placeholder: 'Приветствую тебя, странник! ...', type: 'textarea', name: 'Текст сообщения', grid: '1 / 5', disabled: false },
  ],
  data: {
    greetings__text: channel?.greetings?.text || '',
    greetings__image: channel?.greetings?.image,
  },
});

export const CHANNEL_TARIFF_INPUTS = (referral: Tariff.Dto['referral']) => ({
  inputs: [
    { required: true, show: true, id: 'referral__days', placeholder: 'Введи кол-во дней', type: 'text', mask: '####', name: 'Дней рефералки *', grid: '1 / 13', disabled: false },
    { required: false, show: true, id: 'referral__link', placeholder: 'Введи текст', type: 'text', name: 'Текст над реферальной ссылкой', grid: '1 / 13', disabled: false },
    { required: false, show: true, name: '', placeholder: 'Многоразовая ссылка', id: 'referral__reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: []},
  ],
  data: {
    referral__days: referral?.days,
    referral__link: referral?.link,
    referral__reusable: referral?.reusable,
  },
  submit: {
    name: 'Сохранить'
  }
});

export const CHANNEL_REQUEST_INPUTS = (request: Channel.Dto['request'], subscriber: Subscriber.Dto) => ({
  inputs: [
    { required: request?.email, show: request?.email, id: 'request__email', placeholder: 'Введи Email', type: 'text', name: 'Email *', grid: '1 / 13', disabled: false },
  ],
  data: {
    request__phone: subscriber.phone || '',
    request__email: subscriber.email || '',
    request__phone_code: '+7',
  },
});

export const NEW_CHANNEL_TARIFF_INPUTS = () => ({
  inputs: [
    { required: true, show: true, id: 'name', placeholder: 'Введи название', type: 'text', name: 'Название тарифа *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'duration', placeholder: 'Введи кол-во дней', mask: '####', type: 'text', name: 'Период оплаты *', grid: '1 / 7', disabled: false },
    { required: true, show: true, id: 'price', placeholder: 'Введи стоимость', mask: '#####', type: 'text', name: 'Стоимость, ₽ *', grid: '7 / 13', disabled: false },
    { required: true, show: true, id: 'disposable', placeholder: 'Одноразовый', type: 'checkbox', name: '', grid: '1 / 7', disabled: false },
    { required: true, show: true, id: 'visibility', placeholder: 'Отображать в боте', type: 'checkbox', name: '', grid: '7 / 13', disabled: false },
    { required: true, show: true, id: 'referral__status', placeholder: 'Реферальная программа', type: 'switcher', name: '', grid: '1 / 13', disabled: false },
    { referral__status: true, required: false, show: false, id: 'referral__days', placeholder: 'Введи кол-во дней', type: 'text', mask: '####', name: 'Дней рефералки *', grid: '1 / 13', disabled: false },
    { referral__status: true, required: false, show: false, id: 'referral__link', placeholder: 'Введи текст', type: 'text', name: 'Текст над реферальной ссылкой', grid: '1 / 13', disabled: false },
    { referral__status: true, required: false, show: false, name: '', placeholder: 'Многоразовая ссылка', id: 'referral__reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: []},
    { required: false, name: '', placeholder: 'Цена для новых подписчиков', id: 'double_price__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
    { double_price__status: true, required: false, name: 'Стоимость, ₽ *', show: false, placeholder: 'Введи стоимость', id: 'double_price__price', grid: '1 / 13', type: 'text', mask: '#####', drop: false, showDrop: false, drop_data: [] },
    { double_price__status: true, required: false, name: 'Название тарифа', show: false, placeholder: 'Введи название', id: 'double_price__name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [] },
  ],
  data: {
    name: '',
    duration: '',
    price: '',
    visibility: true,
    disposable: false,
    switcher: false,
    referral__status: false,
    referral__days: '',
    referral__link: '',
    referral__reusable: false,
    double_price__status: false,
    double_price__price: '',
    double_price__name: '',
  },
});

// {
//   inputs: [
//     { required: false, name: '', placeholder: 'Реферальная программа', id: 'referral__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
//     { referral__status: true, required: false, show: false, name: 'Дней рефералки', placeholder: 'Введи кол-во дней', id: 'referral__days', grid: '1 / 13', type: 'text', mask: '####', drop: false, showDrop: false, drop_data: []},
//     { referral__status: true, required: false, show: false, name: 'Текст над реферальной ссылкой', placeholder: 'Введи текст', id: 'referral__link', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: []},
//   ]
// },