import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-058e666c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail--icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["detail common flex-center cursor", _ctx.currentClass]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('selectPayment')), ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.detail.icon), { selected: _ctx.selected }, null, 8, ["selected"]))
    ])
  ], 2))
}