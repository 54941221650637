import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d5e40a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tariff common cursor flex-column" }
const _hoisted_2 = { class: "tariff--name fw600" }
const _hoisted_3 = { class: "tariff--container flex-center-between" }
const _hoisted_4 = { class: "tariff--price flex items-center" }
const _hoisted_5 = { class: "tariff--duration flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_money_icon = _resolveComponent("money-icon")!
  const _component_calendar_icon = _resolveComponent("calendar-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.tariff.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_money_icon),
        _createElementVNode("span", null, _toDisplayString(_ctx.tariff.price) + " ₽", 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_calendar_icon),
        _createElementVNode("span", null, _toDisplayString(_ctx.tariff.duration) + " " + _toDisplayString(_ctx.durationEnd), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "tariff--action",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectTariff')))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(["btn w100", { 'btn--additional': _ctx.selected }])
      }, _toDisplayString(_ctx.selected ? 'Выбран' : 'Выбрать'), 3)
    ])
  ]))
}