
import { defineComponent, markRaw } from "vue-demi";
import { Promocode } from "@/interfaces/promocode/promocode.dto";
import { useAPI } from "@/use";

import Yoomoney from './yoomoney/index.vue';
import Tinkoff from './tinkoff/index.vue';
import Prodamus from './prodamus/index.vue';
import BotpayWorld from './botpay/World.vue';
import BotpayRussian from './botpay/Russian.vue';

export default defineComponent({
  name: 'PaymentMethods',
  props: {
    payment: {
      type: String,
      required: true,
    },
    tariff: {
      type: Object,
      required: true,
    },
    promocode: {
      type: Object,
    },
    auto: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      pending: false,
      components: [
        { id: 'yoomoney', component: markRaw(Yoomoney) },
        { id: 'tinkoff', component: markRaw(Tinkoff) },
        { id: 'promadus', component: markRaw(Prodamus) },
        { id: 'botpay_russian', component: markRaw(BotpayRussian) },
        { id: 'botpay_world', component: markRaw(BotpayWorld) },
      ]
    }
  },
  methods: {
    async submitFreePayment() {
      if (this.pending) return;

      this.pending = true;
      await useAPI().subscriber.paymentMethod(this.$route.query.token as string, this.tariff._id, (this.promocode as Promocode.Dto)?.code);
      this.$router.push('/payment/success');
      this.pending = false;
    },
  },
  computed: {
    currentPayment() {
      return this.components.find(comp => comp.id === this.payment)?.component || this.components[0];
    },
    price() {
      const price = this.tariff.price - (this.tariff.price * (Number(this.promocode?.discount) / 100 || 0));
      return price === 1 ? 2 : price;
    }
  },
})
