import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "btn btn--main btn--disabled w100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.pending)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitPayment && _ctx.submitPayment(...args))),
          class: "btn btn--main w100"
        }, "Оплатить подписку (" + _toDisplayString(_ctx.price) + " ₽)", 1))
      : (_openBlock(), _createElementBlock("button", _hoisted_1, "Формирование платежа..."))
  ]))
}