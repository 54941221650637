export function getCurrentSecondDesc(number: number) {
  return getDescription(number, ['секунд', 'секунду', 'секунды']);
}
export function getCurrentMinutDesc(number: number) {
  return getDescription(number, ['минут', 'минуту', 'минуты']);
}
export function getCurrentHourDesc(number: number) {
  return getDescription(number, ['часов', 'час', 'часа']);
}
export function getCurrentDurationDesc(number: number) {
  return getDescription(number, ['дней', 'день', 'дня']);
}
export function getCurrentSubscribeDesc(number: number) {
  return getDescription(number, ['Подписок', 'Подписка', 'Подписки']);
}
export function getCurrentViewDesc(number: number) {
  return getDescription(number, ['Просмотров', 'Просмотр', 'Просмотра']);
}
export function getCurrentPaymentDesc(number: number) {
  return getDescription(number, ['Платежей', 'Платеж', 'Платежа']);
}

function getDescription(value: number, desc: Array<string>) {
  if (value <= 10 || value >= 20) {
    if (value % 10 === 1) {
      return desc[1];
    } else if ((value % 10 >= 2) && (value % 10 <= 4)) {
      return desc[2];
    }
  }
  return desc[0];
}