
import { dynamicsObject } from "@/interfaces";
import { defineComponent, PropType } from "vue-demi";
import Detail from './index.vue';

export default defineComponent({
  emits: ['selectType', 'selectPayment'],
  name: "PaymentType",
  props: {
    type: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true
    },
    details: {
      type: Array as PropType<Array<dynamicsObject>>,
      required: true,
    },
    payment: {
      type: String,
    },
    isOne: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    currentDetails() {
      const list = this.details.filter(detail => this.type.list.includes(detail._id));
      return list.length < 2 ? [] : list;
    }
  },
  components: {
    Detail,
  }
});
