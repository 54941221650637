
import { defineComponent, PropType } from "vue-demi";
import { CHANNEL_REQUEST_INPUTS } from "@/store/common/channel";
import { Channel } from "@/interfaces/channel/channel.dto";
import { useAPI } from "@/use";
import { commonCheckInputs } from "@/store/common/default/inputs";
import { Modal } from "@/interfaces/modal/modal.dto";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";
import CommonInputs from '@/components/common/inputs/index.vue';
import CommonPhone from '@/components/common/inputs/phone/index.vue';
import Question from './question/index.vue';

export default defineComponent({
  name: "TelegramPaymentRequest",
  emits: ['changeRequestInfo'],
  props: {
    request: {
      type: Object as PropType<Channel.Dto['request']>,
      required: true
    },
    requestInfo: {
      type: Boolean,
      required: true
    },
    subscriber: {
      type: Object as PropType<Subscriber.Dto>,
      required: true
    }
  },
  data() {
    return {
      request_common: CHANNEL_REQUEST_INPUTS(this.request, this.subscriber)
    }
  },
  methods: {
    submitRequest() {
      const check = commonCheckInputs(this.request_common.inputs as Array<Modal.Input>, this.request_common.data);
      if (!check.status) return;

      const result = useAPI().subscriber.updateRequestMethod(this.$route.query.token as string, {...this.request_common.data, questions: this.subscriber.questions});
      result.then(() => this.$emit('changeRequestInfo', true)).catch(err => {
        this.$store.commit('createGeneralNotification', { message: err.response.data.message, type: 'error' });
        this.$emit('changeRequestInfo', false);
      });
    },
    currentSubscriberQuestion(name: string) {
      return this.subscriber.questions.find(q => q.name.trim() === name.trim());
    }
  },
  components: {
    CommonInputs,
    CommonPhone,
    Question
  }
});
