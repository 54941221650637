import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0f04ade"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "question--name fz12" }
const _hoisted_3 = { class: "question--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.question.name) + " *", 1),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Введите ответ...",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question_subscriber.answer) = $event))
      }, null, 512), [
        [_vModelText, _ctx.question_subscriber.answer]
      ])
    ])
  ]))
}