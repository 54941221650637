
import { defineComponent, PropType } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import Type from './Type.vue';

export default defineComponent({
  emits: ["selectPayment", "clearPayment"],
  name: "DetailList",
  props: {
    details: {
      type: Array as PropType<Array<dynamicsObject>>,
      required: true,
    },
    global: {
      type: Array as PropType<Array<dynamicsObject>>,
      required: true
    },
    payment: {
      type: String,
    },
  },
  data() {
    return {
      selected: null as string | null,
    };
  },
  methods: {
    selectType(id: string) {
      this.selected = id;
      this.$emit('clearPayment', id);
    },
  },
  computed: {
    activeTypes() {
      const details = this.details.map(d => d._id);
      const list = this.global.filter(type => type.list.find((el: string) => details.includes(el)));
      return list;
    },
    isOneDetailByType() {
      const types = this.activeTypes;
      if (types.length === 1) {
        const type = types[0];
        this.selectType(type.id);
        const list = this.details.filter(detail => type.list.includes(detail._id));
        return list.length < 2;
      }
      return false;
    },
    currentTitle() {
      if (!this.payment) return 'Выбери способ оплаты';
      const payments = {
        'yoomoney': 'Yoomoney Payment',
        'tinkoff': 'Tinkoff Payment',
        'botpay_russian': 'Botpay Payment Russian',
        'botpay_world': 'Botpay Payment World',
        'prodamus': 'Prodamus Payment',
      };
      return payments[this.payment as 'yoomoney'];
    }
  },
  components: {
    Type,
  },
});
