
import { defineComponent, PropType } from "vue-demi";
import { Tariff } from "@/interfaces/tariff/tariff.dto";
import { getCurrentDurationDesc } from "@/utils/wordEndings";
import { MoneyIcon, CalendarIcon } from '@/plugins/icons';

export default defineComponent({
  emits: ['selectTariff'],
  name: "TelegramPaymentTariff",
  props: {
    tariff: {
      type: Object as PropType<Tariff.Dto>,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    durationEnd() {
      return getCurrentDurationDesc(this.tariff.duration);
    }
  },
  components: {
    MoneyIcon, CalendarIcon
  }
});
