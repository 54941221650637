import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-512d04fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-methods" }
const _hoisted_2 = {
  key: 0,
  class: "type"
}
const _hoisted_3 = {
  key: 1,
  class: "type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.price === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitFreePayment && _ctx.submitFreePayment(...args))),
            class: "btn btn--main"
          }, "Завершить оформление подписки (" + _toDisplayString(_ctx.price) + " ₽)", 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentPayment), {
            token: _ctx.$route.query.token,
            tariff: _ctx.tariff._id,
            promocode: _ctx.promocode?._id,
            auto: _ctx.auto,
            price: _ctx.price
          }, null, 8, ["token", "tariff", "promocode", "auto", "price"]))
        ]))
  ]))
}